.App {
  .top-bar {
    background: #fff;
    padding-right: 20px;
    padding-bottom: 12px;
    .container {
      align-items: center;
    }
    .account {
      padding: 0px 10px;
      cursor: pointer;

      .avator {
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }

  .side-nav {
    .logo {
      -webkit-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s;
      overflow: hidden;
      margin: 10px 0px 20px 0px;
      text-align: center;
      white-space: nowrap;

      img {
        width: 50px;
        padding-right: 5px;
      }

      h2 {
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 300;
        margin: 2px;
        overflow: hidden;
      }
    }

    ul.ant-menu, .ant-layout-sider-trigger {
      background-color: #2d373c;
    }

    ul.ant-menu-dark .ant-menu-inline.ant-menu-sub {
      -webkit-box-shadow: inherit;
      box-shadow: inherit;
    }

    ul li.ant-menu-item-selected {
      background-color: #1f282d;
    }
  }

  .side-nav.ant-layout-sider-collapsed {
      .logo {
        padding-left: 4px;
      }

      h2 {
      max-width: 0;
      display: inline-block;
      opacity: 0;
    }
  }

  .login-bg {
    height: 100vh;
    background-image: url("/cover.jpg") !important;
    background-size: cover;
    .login-box {
      background-color: whitesmoke;
      border: solid 1px #ccc;
      border-radius: 4px;
      padding: 40px;
      padding-bottom: 20px;
      position: absolute;
      right:  120px;
      bottom: 100px;
      .login-logo {
        text-align: center;
        margin-bottom: 20px;
        img {
          resize: contain;
          width: 100px;
          height: 100px;
        }
      }

      h2 {
        margin-bottom: 30px;
      }

      button {
        height: 40px;
        width: 100%;
      }

      .login-error {
        height: 22px;
        color: red;
      }
    }
  }

  .ant-layout-content {
    background-color: #f5f8fa;
    padding: 8px;
  }

  .pagingation {
    float: right;

    button {
      border-color: transparent;
      background-color: transparent;
      margin: 16px;
    }
  }

  .ant-card {
    border-radius: 5px;
    padding: 5px;

    .ant-card-head {
      margin: 0px -5px;
      padding-left: 29px;
    }
  }

  .ant-card.detail-view {
    color: #222;
    margin-bottom: 20px;

    .ant-card-body {
      line-height: 2;
    }
  }

  span.item-label {
    color: #777;
  }

  div.photo-grid {
    display: inline-block;
    img {
      border-radius: 5px;
      margin: 5px 5px 5px 0px;
    }
  }

  .text-editor {
    border: 1px solid #F1F1F1;
    padding: 5px;
    min-height: 200px;
  }
}

.ant-layout {
  .ant-layout-header {
    background: #fff;
    padding: 10px;
    height: inherit;
    line-height: inherit;
    border-bottom: 1px solid #e8e8e8;
  }

  .ant-layout-footer {
    text-align: center;
    background: #fff;
    padding: 10px 50px;
    border-top: 1px solid #e8e8e8;
  }
}

div.img-select {
  text-align: center;
  padding-bottom: 5px;
  width: 150px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }

  img {
    margin-bottom: 4px;
  }
}

.ant-modal-body {
  form {
    margin-top: 24px;
  }
}

.fa {
  margin-right: 8px;
}

.code-box {
  /*border: 1px solid #e9e9e9;
  border-radius: 4px;*/
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0 0 16px;
  -webkit-transition: all .2s;
  transition: all .2s;
}

.code-box section {
  position: relative;
  border-bottom: 1px solid #e9e9e9;
  padding: 10px 10px 10px 10px;
}

.code-box section.last {
  border-bottom: 0px;
}

.code-box-title {
  position: absolute;
  top: -14px;
  padding: 1px 8px;
  margin-left: -8px;
  border-radius: 4px;
  background: #fff;
  -webkit-transition: background-color .4s;
  transition: background-color .4s;
  color: rgba(0,0,0,.65);
  font-size: 14px;
  font-weight: 700;
}

section.action-panel {
  padding: 10px;
  border: none;
  border-top: 1px solid #e9e9e9;
}

section.action-panel button {
  min-width: 120px;
  min-height: 30px;
  margin-right: 20px;
}

section.navigation-panel {
  padding: 0px;
  border: none;
}

section.navigation-panel button {
  min-width: 120px;
  min-height: 30px;
  margin-left: 10px;
}

.add-or-select {
  display: inline-block;
  width: 100%;
}

.add-or-select .ant-select {
  max-width: 300px;
  margin-right: 4px;
}

div.add-or-select > div.parent-display > span {
  font-size: 10px;
  margin: 0px;
}

div.add-or-select > div.parent-display {
  margin-top: -30px;
  padding: 0px;
  height: 25px;
  color: blue;
}

.ant-form-table {
  margin-bottom: 24px;
}

.ant-table-tbody > tr > td {
  vertical-align: top;
}

.ant-table-row .ant-form-item {
  margin-bottom: inherit;
}

.ant-table-wrapper tr.ant-table-row td {
  padding: 4px;
  word-break: break-word;
}

.ant-table-wrapper thead.ant-table-thead th {
  font-weight: bold;
  padding: 6px 4px;
  word-break: break-word;
}

.ant-table-footer button.ant-btn {
  margin-top: inherit;
}

.ant-table-footer .right {
  text-align: right;
}

td.number {
  text-align: right;
}

.columnAlignRight {
  text-align: right !important;
}

button.silence {
  border: none;
}

button.pull-right {
  float: right;
}

.ant-table-thead > tr > th:not(:first-child), .ant-table-tbody > tr > td:not(:first-child) {
    text-align: center;
}

.summary-box {
  margin: 0
}
.ant-form-text {
  font-weight: bold;
}

.status .ant-tag .anticon {
  margin-right: 6px;
}

#commit-hash {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 11px;
  padding: 2px 4px;
  border: solid 1px #ccc;
  border-radius: 4px;
  margin: 0px 6px;
}

.container {
  display: flex;
}

.left-half {
  flex: 1;
  padding-top: 15;
  padding-bottom: 15;
}

.right-half {
  flex: 1;
  float: right;
  padding-top: 15;
  padding-bottom: 15;
}

.box {
  position: relative;
  //border: 1px solid #BBB;
  //background: #EEE;
}
.ribbon {
  position: absolute;
  left: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 100px; height: 100px;
  text-align: center;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 135px;
  display: block;
  //background: #79A70A;
  //background: linear-gradient(#F79E05 0%, #8F5408 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 31px; left: -27px;
}
.ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #8F5408;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8F5408;
}
.ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #8F5408;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8F5408;
}
@primary-color: #0baf4f;@font-family: 'Open Sans', sans-serif;